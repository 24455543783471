/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import classNames from '../../classNames'
import { UserTasksContext } from '../../context/UserTasksContextProvider'
import LeftCollapseLayout from '../layout/LeftCollapseLayout'
import UserTaskDetails from './UserTaskDetails'
import { EmptyState, SpinnerIcon } from '@sistemiv/s-components'
import { TaskItemProps } from '@sistemiv/s-components/dist/esm/components/task-list/TaskItem'
import { useTasksPagination, useTaskDetails, useTaskClaim, useProcessDiagram } from '../../repositories'
import { imageBase } from '../../services/http-common'
import { useProfile } from '../../repositories/user/user-profile.repository'
import TaskFilter from './task-list/TaskFilter'
import TaskList from './task-list/TaskList'
import UserTaskHeader from './UserTaskHeader'
import { useTranslation } from 'react-i18next'
import { SignalRContext } from '../../pages/Dashboard'
import { useQueryClient } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
// //import { useUserNodeAndGroups } from '../../repositories/user/userNodeAndGroups.repository'
// //import { useUserInfo } from '../../repositories/user/userNodeAndGroups.repository'

const UserTasks: FC = () => {
  const { org } = useParams()
  const {
    selectedId,
    setSelectedId,
    selectedIndex,
    setSelectedIndex,
    sortValue,
    setSortOrder,
    sortOrder,
    setSortValue,
    query,
    setQuery,
    filterOptions,
  } = useContext(UserTasksContext)
  const [isListCollapsed, setIsListCollapsed] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  // const [searchValue, setSearchValue] = useState<string>(query ?? '')
  const { instance } = useMsal()
  const {
    data: taskListRawData,
    hasNextPage,
    fetchNextPage,
    isFetching: isTaskListFetching,
    isPending: isTaskListLoading,
  } = useTasksPagination({ order: sortValue.value, sort: sortOrder ? 'desc' : 'asc', search: query })
  const { data: task, isLoading: taskLoading } = useTaskDetails(selectedId)
  const { mutateAsync: taskClaimMutation, isPending: isTaskClaiming } = useTaskClaim()
  const { data: profile } = useProfile({
    userId: task?.assigneeId,
    enabled: task?.assigneeType === 'User' || task?.assigneeType === 'InstanceStarter',
  })
  const [taskList, setTaskList] = useState<TaskItemProps[]>([])
  // const { data: userNodeGroups } = useUserNodeAndGroups({
  //   org: org!,
  // })
  // console.log(userNodeGroups)

  const { data: diagram } = useProcessDiagram({
    processDefinitionId: task?.processDefinitionId ?? '',
    processInstanceId: task?.processInstanceId ?? '',
  })
  const queryClient = useQueryClient()

  console.log(selectedId)
  console.log(task)

  // useEffect(() => {
  //   console.log('effect')
  //   console.log(selectedId)
  //   console.log(SignalRContext.connection?.state)
  //   if (!selectedTask?.assigneeId || !SignalRContext.connection || SignalRContext.connection?.state !== 'Connected')
  //     return
  //   console.log('effect')
  //   console.log(selectedId)
  //   console.log(SignalRContext.connection)
  //   SignalRContext.invoke('add-filter', 'userId', selectedTask?.assigneeId)
  //   if (userNodeGroups) {
  //     userNodeGroups?.groups &&
  //       userNodeGroups?.groups.length > 0 &&
  //       userNodeGroups.groups.forEach((group) => {
  //         SignalRContext.invoke('add-filter', 'group', group.id)
  //       })
  //     userNodeGroups?.organizationalNode &&
  //       SignalRContext.invoke('add-filter', 'organizationalNode', userNodeGroups.organizationalNode.id)
  //   }

  //   return () => {
  //     console.log('return')
  //     SignalRContext.invoke('remove-filter', 'userId', selectedTask?.assigneeId)
  //     if (userNodeGroups) {
  //       userNodeGroups?.groups &&
  //         userNodeGroups?.groups.length > 0 &&
  //         userNodeGroups.groups.forEach((group) => {
  //           SignalRContext.invoke('remove-filter', 'group', group.id)
  //         })
  //       userNodeGroups?.organizationalNode &&
  //         SignalRContext.invoke('remove-filter', 'organizationalNode', userNodeGroups.organizationalNode.id)
  //     }
  //   }
  // }, [selectedTask, selectedId, SignalRContext.connection?.state])

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (payload, data) => {
      console.log(payload)
      console.log(data)
      console.log(instance.getActiveAccount()?.localAccountId)
      if (
        ['UserTaskInstanceClaimedEvent'].includes(payload) &&
        data.UserId !== instance.getActiveAccount()?.localAccountId &&
        selectedId === data.UserTaskInstanceId
        // taskList.find((task) => task.id === data.UserTaskInstanceId)
      ) {
        console.log('reset')
        setSelectedId('')
        navigate(`/${org}/tasks`)
        setTaskList((old) => old.filter((task) => task.id !== data.UserTaskInstanceId))
      }
      if (
        ['UserTaskInstanceClaimedEvent'].includes(payload) &&
        data.UserId !== instance.getActiveAccount()?.localAccountId &&
        data.UserTaskInstanceId !== selectedId
      ) {
        console.log('refresh tasks')
        queryClient.invalidateQueries({
          queryKey: ['tasks-paginated', org, 16],
          refetchType: 'all',
        })
        // setSelectedId('')
        // navigate(`/${org}/tasks`)
        // queryClient.invalidateQueries({
        //   queryKey: ['tasks-paginated', org, 16],
        //   refetchType: 'all',
        // })
      }
      if (
        [
          'UserTaskStartedEvent',
          // 'UserTaskEndedEvent',
          // 'UserTaskUpdatedEvent',
          // 'UserTaskAssigneeChangedEvent',
        ].includes(payload)
        // &&
        // data.AssigneeId
        //data.AssigneeId !== selectedTask?.assigneeId
      ) {
        console.log('refresh tasks')
        // setSelectedId('')
        // navigate(`/${org}/tasks`)
        queryClient.invalidateQueries({
          queryKey: ['tasks-paginated', org, 16],
          refetchType: 'all',
        })
      }
    },
    [],
  )

  useEffect(() => {
    console.log('reset list1')
    if (!taskListRawData) return
    console.log('reset list')
    const freshList: any[] = []
    for (const group of taskListRawData.pages) {
      for (const task of group.results) {
        freshList.push({
          id: task.id,
          name: task.name,
          dueDate: task.dueDate,
          processName: task.processDefinitionName,
          processColor: task.processDefinitionColor,
          code: task.processInstanceCode ?? 'OM-10011',
          processInstanceId: task.processInstanceId,
          processDefinitionKey: task.processDefinitionKey,
          selected: false,
        })
      }
    }
    setTaskList(freshList)
  }, [taskListRawData])

  useEffect(() => {
    console.log('params effec')
    if (searchParams.has('search')) {
      const search = searchParams.get('search')
      setQuery(search!)
    }
    if (searchParams.has('id')) {
      const id = searchParams.get('id')
      console.log('params id: ', id)
      setSelectedId(id!)
    } else setSelectedId('')
  }, [searchParams])

  useEffect(() => {
    if (!org) return
    console.log('selected effect')
    console.log(selectedId)
    console.log(searchParams)
    console.log(searchParams.has('id'))
    if (taskList.length > 0 && selectedId === '' && (!searchParams.has('id') || !searchParams.get('id'))) {
      console.log('navigate')
      navigate({
        pathname: `/${org}/tasks`,
        search: `?id=${taskList[0].id}`,
      })
    } else if (taskList.length === 0 && selectedId !== '' && !searchParams.has('id')) {
      console.log('nesto')
      setSelectedId('')
      setSelectedIndex(0)
    }
    if (
      !taskList.find((t) => t.id === selectedId) &&
      selectedId !== '' &&
      taskList.length > 0
      // &&
      // !searchParams.has('id')
    ) {
      console.log('nesto 2')
      // if (task?.processInstanceCode) {
      //   setQuery(task?.processInstanceCode)
      // } else {
      let newIndex = 0
      if (selectedIndex >= 0 || selectedIndex < taskList.length) {
        newIndex = selectedIndex
      } else if (selectedIndex + 1 < taskList.length) {
        newIndex = selectedIndex + 1
      } else if (selectedIndex - 1 >= 0) {
        newIndex = selectedIndex - 1
      }

      //setSelectedId(taskList[newIndex] ? taskList[newIndex].id : taskList[0].id)
      navigate({
        pathname: `/${org}/tasks`,
        search: `?id=${taskList[newIndex] ? taskList[newIndex].id : taskList[0].id}`,
      })
      // }
    }
    if (selectedId !== '') {
      setSelectedIndex(taskList.findIndex((t) => t.id === selectedId))
    }
    console.log(selectedId)
    console.log(selectedIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, taskList, org])

  const observerRef = useRef<IntersectionObserver | null>(null)
  const lastTaskRef = useCallback(
    (node) => {
      if (isTaskListFetching) return
      if (observerRef.current) observerRef.current.disconnect()
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage()
        }
      })
      if (node) observerRef.current.observe(node)
    },
    [isTaskListFetching, fetchNextPage, hasNextPage],
  )

  const handleScroll = (e) => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 2
    if (bottom) {
      if (!hasNextPage || isTaskListFetching) return
      fetchNextPage()
    }
  }

  const handleClaiming = () => {
    if (!org) return
    taskClaimMutation({ id: selectedId })
  }

  return taskList.length < 0 && query === '' ? (
    <EmptyState emptyText={t('UserTasks.noOpenTasks')} />
  ) : (
    <LeftCollapseLayout className={classNames(!isListCollapsed ? 'pl-6' : '')}>
      <div
        className={classNames(
          'max-w-xs w-full border-r h-full flex flex-col border-gray-200 py-3 divide-y divide-gray-300',
        )}
      >
        <div className='flex justify-start items-start gap-x-2 pb-2'>
          <div className={classNames(isListCollapsed ? 'hidden' : '', 'w-full')}>
            <TaskFilter
              sortOptions={filterOptions}
              sortValue={sortValue}
              onSortValueChange={setSortValue}
              sortOrder={sortOrder}
              onSortOrderChange={(value) => setSortOrder(value)}
              searchValue={query}
              onSearch={(o) => {
                setQuery(o)
                // setSearchValue(o)
              }}
            />
          </div>

          <button
            className='p-3 hover:bg-sky-50 active:bg-sky-100 rounded-full'
            onClick={() => setIsListCollapsed((collapsed) => !collapsed)}
          >
            {!isListCollapsed ? (
              <ChevronLeftIcon className='h-4 w-4 text-gray-400 stroke-2' />
            ) : (
              <ChevronRightIcon className='h-4 w-4 text-gray-400 stroke-2' />
            )}
          </button>
        </div>

        {isTaskListLoading && !taskList.length && (
          <div className='w-full pt-6 flex justify-center'>
            <SpinnerIcon className='text-sky-500'></SpinnerIcon>
          </div>
        )}
        {taskList.length > 0 ? (
          <div
            className={classNames(isListCollapsed ? 'hidden' : '', 'h-full overflow-y-auto')}
            onScroll={handleScroll}
          >
            <TaskList
              tasks={taskList}
              onTaskSelected={(id: string) => navigate({ pathname: `/${org}/tasks`, search: `?id=${id}` })}
              selectedId={selectedId}
            />
            <div ref={lastTaskRef}></div>
            {isTaskListFetching && (
              <div className='w-full pt-6 flex justify-center'>
                <SpinnerIcon className='text-sky-500'></SpinnerIcon>
              </div>
            )}
          </div>
        ) : (
          !isTaskListLoading && (
            <div className={classNames(isListCollapsed ? 'hidden' : '')}>
              <div className={classNames('mt-5 pr-6')}>
                <EmptyState emptyText={t('UserTasks.noOpenTasks')} />
              </div>
            </div>
          )
        )}
      </div>
      {selectedId !== '' && !taskLoading && (
        <div className='divide-y divide-gray-300 flex flex-col'>
          <UserTaskHeader
            taskName={taskList.find((t) => t.id === selectedId)?.name ?? ''}
            currentTask={selectedIndex + 1}
            totalTasks={taskList.length}
            onNextClicked={() => {
              const newTask = taskList[selectedIndex + 1]
              navigate({
                pathname: `/${org}/tasks`,
                search: `?id=${newTask.id}`,
              })
            }}
            onPreviousClicked={() => {
              const newTask = taskList[selectedIndex - 1]
              navigate({
                pathname: `/${org}/tasks`,
                search: `?id=${newTask.id}`,
              })
            }}
            onGoToHomeDashboardClicked={() => navigate({ pathname: `/${org}` })}
            onGoToProcessInstanceClicked={() =>
              navigate({
                pathname: `/${org}/process-instance/${task?.processDefinitionKey}/${task?.processInstanceId}`,
              })
            }
            onClaimTask={() => handleClaiming()}
            claiming={isTaskClaiming}
            unclaimed={
              !task?.assigneeId || (task?.assigneeId && !['User', 'InstanceStarter'].includes(task?.assigneeType))
            }
            assigneeImage={`${profile?.data.id ? `${imageBase}/${task?.assigneeId}/76` : ''}`}
            userFirstName={profile?.data.firstName}
          />
          <UserTaskDetails
            diagram={diagram}
            selectedTask={task}
            setTaskList={setTaskList}
            isTaskLoading={taskLoading}
          />
        </div>
      )}
    </LeftCollapseLayout>
  )
}

export default UserTasks
